import React, { useState, useEffect } from "react";
import './Header.less';
import { Link } from "react-router-dom";
import { useSelector, useAppDispatch } from '../../redux/hook';
import { globalSlice } from '../../redux/global/slice';
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Logo from '../../assets/common/logo.png';
import iconLng from '../../assets/common/icon_lng.png';


export const Header: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { language, headerIn, menuIdx } = useSelector(state => state.global);
    const changeLanguage = (lng: string) => {
       i18n.changeLanguage(lng);
       dispatch(
         globalSlice.actions.changeLanguage({
           language: lng
         }),
      );
    }
   // 路由相关
   const location = useLocation();
   useEffect(() => {
    // 在组件加载时以及每次路由变化时执行的逻辑
     const hash = window.location.hash;
     if(!hash) {
       window.scrollTo(0,0);
     }
     console.log(`The current URL is ${location.pathname}`);
     let mIdx = 0;
     if(location.pathname.indexOf('/product') > -1) {
       mIdx = 1;
     } else if(location.pathname.indexOf('/quality') > -1) {
       mIdx = 2;
     }  else if(location.pathname.indexOf('/about') > -1) {
       mIdx = 3;
     } 
      dispatch(
         globalSlice.actions.changeLanguage({
           menuIdx: mIdx
         }),
      );
    }, [location]);

    // 鼠标移动上去
     const [isHovered, setIsHovered] = useState(false);
      const handleMouseEnter = () => {
        setIsHovered(true);
      };

      const handleMouseLeave = () => {
        setIsHovered(false);
      };


    return (
        <div className={headerIn ? "headerContainer": "headerContainer out"}>
            <div className="headerContainer__row"  
              onMouseEnter={handleMouseEnter}
               onMouseLeave={handleMouseLeave} >
                <div className="headerContainer__logo">
                    <Link to="/" >
                        <img src={Logo} alt="" className="headerContainer__logo-image" />
                    </Link>
                </div>
                <div  className={language === 'en' ? 'headerContainer__menu en': 'headerContainer__menu'}>
                <div className={!isHovered  && menuIdx === 0 ? "headerContainer__menuCol cur": "headerContainer__menuCol"}>
                        <Link to="/" className="headerContainer__menu-title" >
                            {t("header.menu.title_home")}
                        </Link>
                    </div>
                     <div className={!isHovered  && menuIdx === 1 ? "headerContainer__menuCol cur": "headerContainer__menuCol"} >
                        <Link to="/product" className="headerContainer__menu-title" >
                            {t("header.menu.title_product")}
                        </Link>
                        <div className="headerContainer__items">
                            <Link to="/product" className="headerContainer__menu-itemLink" >
                             {t("header.menu.title_product_1")}
                            </Link>
                            <Link to="/product#consume" className="headerContainer__menu-itemLink" >
                             {t("header.menu.title_product_2")}
                            </Link>
                            <Link to="/product#enterpris" className="headerContainer__menu-itemLink" >
                             {t("header.menu.title_product_3")}
                            </Link>
                            <Link to="/product#car" className="headerContainer__menu-itemLink" >
                             {t("header.menu.title_product_4")}
                            </Link>
                        </div>
                    </div>
                     <div className={!isHovered  && menuIdx === 2 ? "headerContainer__menuCol cur": "headerContainer__menuCol"}>
                        <Link to="/quality" className="headerContainer__menu-title" >
                            {t("header.menu.title_hons")}
                        </Link>
                        <div className="headerContainer__items">
                            <Link to="/quality" className="headerContainer__menu-itemLink" >
                             {t("header.menu.title_hons_1")}
                            </Link>
                            <Link to="/quality#honors" className="headerContainer__menu-itemLink" >
                             {t("header.menu.title_hons_2")}
                            </Link>
                        </div>
                    </div>
                     <div className={!isHovered  && menuIdx === 3 ? "headerContainer__menuCol cur": "headerContainer__menuCol"}>
                        <Link to="/about" className="headerContainer__menu-title" >
                            {t("header.menu.title_about")}
                        </Link>
                        <div className="headerContainer__items">
                            <Link to="/about#introduce" className="headerContainer__menu-itemLink" >
                             {t("header.menu.title_about_1")}
                            </Link>
                            <Link to="/about#history" className="headerContainer__menu-itemLink" >
                             {t("header.menu.title_about_2")}
                            </Link>
                            <Link to="/about#culture" className="headerContainer__menu-itemLink" >
                             {t("header.menu.title_about_3")}
                            </Link>
                            <Link to="/about#contact" className="headerContainer__menu-itemLink" >
                             {t("header.menu.title_about_4")}
                            </Link>
                        </div>
                      </div>  
                </div>
                <div className="headerContainer__language">
                    <img src={iconLng} alt="" className="headerContainer__language-ico" />
                    <a className={language== 'zh' ? "headerContainer__language-link active": "headerContainer__language-link "} onClick={() => changeLanguage('zh')}>中文</a>
                    &nbsp;&nbsp;/&nbsp;&nbsp;
                    <a className={language== 'en' ? "headerContainer__language-link active": "headerContainer__language-link "} onClick={() => changeLanguage('en')} >EN</a>
                </div>
            </div>
        </div>
     )    
}