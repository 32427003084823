import type { FC } from "react";
import type { RouteObject } from "react-router-dom";
import { lazy } from 'react';
import { Navigate } from 'react-router';
import { useRoutes } from 'react-router-dom';
import MainLayout  from '../pages/MainLayout';
import HomePage from "../pages/home/Home";
import AboutPage from "../pages/about/About";
import ProductPage from "../pages/product/Product";
import QualityPage from "../pages/quality/Quality";


const RouteList: RouteObject[] = [
   {
    path: '/',
    element: <MainLayout />,
    children: [
    {
      path: '/home',
      element: <HomePage />,
    },
    {
      path: '/about',
      element: <AboutPage />,
    },
    {
      path: '/product',
      element: <ProductPage />,
    },
     {
      path: '/quality',
      element: <QualityPage />,
     },
     {
      path: '/',
      element: <HomePage />,
    },
   ]
 },
]

  
  const RenderRouter: FC = () => {
    const element = useRoutes(RouteList);
    return element;
  };
  
  export default RenderRouter;