
import React , { useEffect, useRef } from 'react';
import './Quality.less';
import { useSelector, useAppDispatch } from '../../redux/hook';
import { globalSlice } from '../../redux/global/slice';
import { useLocation } from 'react-router-dom';
import TerabyteComponent from '../../components/terabyte/Terabyte';
import MapComponent from './components/map/Map';
import CarouselComponent from './components/carousel/Carousel';
import MiddlePicComponent from './components/middlePic/MiddlePic';
import HonorsComponent from "./components/honors/Honors";
import { Footer } from '../../components';

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const boxVariant = {
  visible: { opacity: 1, translateY: 0,  transition: { duration: 1 } },
  hidden: { opacity: 0, translateY: 100}
};

const QualityPage: React.FC = () => {  

  const { language } = useSelector(state => state.global); 

  const dispatch = useAppDispatch();
     useEffect(() => {
        dispatch(
          globalSlice.actions.setGlobalSlice({
           headerIn: true
          })
         )
    }, []);

    const location = useLocation();
    const targetHonorsRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const hash = window.location.hash;
        if (hash && hash === '#honors') {
          // 将页面滚动到目标组件的位置
          window.scrollTo(0,0);
          targetHonorsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [location]);

   const control = useAnimation();
   const [ref, inView] = useInView();

   useEffect(() => {
      if (inView) {
        control.start("visible");
      } else {
        control.start("hidden");
      }
    }, [control, inView]);
   
    return(
        <div className="qualityPage">
            <div className="qualityPage__middleBg"></div>
            <div className="qualityPage__fixWrap">
                <div className="qualityPage__top" >
                  <motion.div
                  ref={ref}
                  variants={boxVariant}
                  initial="hidden"
                  animate={control}
                >
                 <div className="content-row">
                  <TerabyteComponent />
                  <MapComponent  language={language} />
                  <CarouselComponent />
                 </div>
                 </motion.div>
                </div>
                
                <div className="qualityPage__middleEmty">
                </div>
                <div className="qualityPage__foot">
                  <div className="content-row" ref={targetHonorsRef}>
                    <HonorsComponent language={language} />
                  </div>
                  <Footer />
               </div>
            </div>
            
        </div>
    )
}

export default QualityPage;