import React , { useEffect, useState, useRef } from 'react';
import { useSelector, useAppDispatch } from '../../redux/hook';
import { globalSlice } from '../../redux/global/slice';
import './About.less';

import TerabyteComponent from '../../components/terabyte/Terabyte';
import MapComponent from './components/map/Map';
import IntroduceComponent from './components/introduce/Introduce';
import HistoryComponent from './components/history/History';
import CultureComponent from './components/culture/Culture';
import ContactComponent from './components/contact/Contact';
import CompanyComponent from './components/company/Company';


const AboutPage: React.FC = () => {

    const { language } = useSelector(state => state.global); 

    const dispatch = useAppDispatch();
    const [count, setCount] = useState(0);

     useEffect(() => {
        dispatch(
          globalSlice.actions.setGlobalSlice({
           headerIn: true
          })
         )
      const intervalId = setInterval(() => {
        setCount(prevCount => prevCount + 1);
      }, 1000);

       // 在组件卸载时清除 interval
       return () => clearInterval(intervalId);
    }, []);

    return(
        <div className="accountPage">
            <div className="content-row">
              <TerabyteComponent  />
              <MapComponent count={count} language={language} />
               <IntroduceComponent language={language} />
              <HistoryComponent language={language} />
            </div>
               <CultureComponent language={language} />
            <div className="content-row">
                <ContactComponent count={count} language={language} />
                <CompanyComponent language={language} />
            </div>
        </div>
    )
}

export default AboutPage;