import React, { useState, useEffect, useRef } from 'react';
import './Introduce.less';
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";


const boxVariant = {
  visible: { opacity: 1, translateY: 0,  transition: { duration: 1, delay: 1 } },
  hidden: { opacity: 0, translateY: 50}
};


interface IntroduceProps {
  language: string;
}

const Introduce: React.FC<IntroduceProps> = ({language}) => {
    const { t } = useTranslation();
     const location = useLocation();
    const targetRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const hash = window.location.hash;
        if (hash && hash === '#introduce') {
          // 将页面滚动到目标组件的位置
          targetRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [location]);

   const control = useAnimation();
   const [ref, inView] = useInView();
   useEffect(() => {
      if (inView) {
        control.start("visible");
      } else {
        control.start("hidden");
      }
    }, [control, inView]);


    return(
      <motion.div
        ref={ref}
        variants={boxVariant}
        initial="hidden"
        animate={control}
      >
        <div className="aboutIntroduce" ref={targetRef} >
            <div className="aboutIntroduce__row">
                <div className="aboutIntroduce__left">
                    <div className="aboutIntroduce__title">
                    {t("home.intro_title")}
                   </div>
                   <div className={language === 'en' ? "aboutIntroduce__info en" :   "aboutIntroduce__info"} >
                    <p className="aboutIntroduce__info-p">
                     {t("home.intro_info_1")}
                      <br />
                      <br />
                     {t("home.intro_info_2")}
                  </p>
                   </div>
                </div>
                <div className="aboutIntroduce__right">
                    <div className="aboutIntroduce__items">
                        <div className="aboutIntroduce__itemBg design">
                            <div className="aboutIntroduce__itemLabel">
                             {t("about.intro_item_design")}
                            </div>
                        </div>
                        <div className="aboutIntroduce__itemBg develop">
                            <div className="aboutIntroduce__itemLabel">
                             {t("about.intro_item_develop")}
                            </div>
                        </div>
                        <div className="aboutIntroduce__itemBg produces" style={{marginTop: 20}}>
                            <div className="aboutIntroduce__itemLabel">
                             {t("about.intro_item_produces")}
                            </div>
                        </div>
                        <div className="aboutIntroduce__itemBg sale" style={{marginTop: 20}}>
                            <div className="aboutIntroduce__itemLabel">
                             {t("about.intro_item_sale")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </motion.div>
    )
}

export default Introduce;