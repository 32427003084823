import React, { useState, useEffect, useRef } from 'react';
import './HistoryItem.less';
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

interface HistoryItemProps {
    delay: number;
    src: string;
    desc: string;
}

const HistoryItem: React.FC<HistoryItemProps>= ({delay, src, desc}) => {
   const { t } = useTranslation();


  const boxVariant = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: delay } },
    hidden: { opacity: 0, translateY: 100 }
  };

   const control = useAnimation();
   const [ref, inView] = useInView();

   useEffect(() => {
      if (inView) {
        control.start("visible");
      } else {
        control.start("hidden");
      }
    }, [control, inView]);

    return(
      <motion.div
              ref={ref}
              variants={boxVariant}
              initial="hidden"
              animate={control}
            >
        <div className="aboutHistory__item">
            <img src={src} alt="" className="aboutHistory__itemImg" />
            <div className="aboutHistory__itemDesc">{desc}</div>
        </div>
      </motion.div>
      
    )
}

export default HistoryItem;