import React, { useState, useEffect, useRef } from 'react';
import './History.less';
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import HistoryItem from '../historyItem/HistoryItem'

import Img2013 from '../../../../assets/about/2013.png';
import Img2014 from '../../../../assets/about/2014.png';
import Img2015 from '../../../../assets/about/2015.png';
import Img2016 from '../../../../assets/about/2016.png';
import Img2017 from '../../../../assets/about/2017.png';
import Img2018 from '../../../../assets/about/2018.png';
import Img2019 from '../../../../assets/about/2019.png';
import Img2020 from '../../../../assets/about/2020.png';
import Img2021 from '../../../../assets/about/2021.png';
import Img2022 from '../../../../assets/about/2022.png';
import Img2023 from '../../../../assets/about/2023.png';

const boxVariant1 = {
  visible: { opacity: 1, translateY: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, translateY: 100 }
};

interface HistoryProps {
  language: string;
}

const History: React.FC<HistoryProps>= ({language}) => {
   const { t } = useTranslation();

    const location = useLocation();
    const targetRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const hash = window.location.hash;
        if (hash && hash === '#history') {
          // 将页面滚动到目标组件的位置
          targetRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [location]);


   const control = useAnimation();
   const [ref, inView] = useInView();

   useEffect(() => {
      if (inView) {
        control.start("visible");
      } else {
        control.start("hidden");
      }
    }, [control, inView]);

    return(
        <div className="aboutHistory" ref={targetRef} >
            <div className="aboutHistory__title">{t("about.history_title")}</div>
            <div className={language === 'en' ? "aboutHistory__list en": "aboutHistory__list"}>
                <HistoryItem
                    delay={0}
                    src={Img2013}
                    desc={t("about.history_2013")}
                 />
                  <HistoryItem
                    delay={0.5}
                    src={Img2014}
                    desc={t("about.history_2014")}
                 />
                 <HistoryItem
                    delay={1}
                    src={Img2015}
                    desc={t("about.history_2015")}
                 />
                <HistoryItem
                    delay={1}
                    src={Img2016}
                    desc={t("about.history_2016")}
                 />
                  <HistoryItem
                    delay={1}
                    src={Img2017}
                    desc={t("about.history_2017")}
                 />
                  <HistoryItem
                    delay={1}
                    src={Img2018}
                    desc={t("about.history_2018")}
                 />
                  <HistoryItem
                    delay={1}
                    src={Img2019}
                    desc={t("about.history_2019")}
                 />
                  <HistoryItem
                    delay={1}
                    src={Img2020}
                    desc={t("about.history_2020")}
                 />
                 <HistoryItem
                    delay={1}
                    src={Img2021}
                    desc={t("about.history_2021")}
                 />
                 <HistoryItem
                    delay={1}
                    src={Img2022}
                    desc={t("about.history_2022")}
                 />
                 <HistoryItem
                    delay={1.5}
                    src={Img2023}
                    desc={t("about.history_2023")}
                 />
            </div>
        </div>
      
    )
}

export default History;