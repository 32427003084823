import React, { CSSProperties } from 'react';
import { Carousel } from 'react-responsive-carousel';

import imgPrev from "../../../../assets/home/prev.png";
import imgNext from "../../../../assets/home/next.png";

interface CustomArrowProps {
  direction: string;
  onClick: (event: React.MouseEvent) => void;
}

// 自定义箭头组件
const CustomArrow = ({ direction, onClick }:  CustomArrowProps ) => {
  const arrowStyles: CSSProperties = {
    position: 'absolute',
    zIndex: 2,
    top: '45%',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
  };

  return (
    <>
      {direction === 'left' ? <a
      style={{ ...arrowStyles, [direction]: -80 }}
      onClick={onClick}
    >
      <img src={imgPrev} style={{ width: 40, height: 40 }} />
    </a> : <a
      style={{ ...arrowStyles, [direction]: -80 }}
      onClick={onClick}
    >
      <img src={imgNext} style={{ width: 40, height: 40 }} />
    </a> }
    </>
    
  );
};


export default CustomArrow;
