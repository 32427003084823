import React, { useEffect } from 'react';
import './Company.less';
import { useTranslation } from "react-i18next";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const boxVariant = {
  visible: { opacity: 1, translateY: 0,  transition: { duration: 1 } },
  hidden: { opacity: 0, translateY: 100 }
};

interface CompanyProps {
    language: string;
}

const Company : React.FC<CompanyProps> = ({ language }) => {
    const { t } = useTranslation();

     const control = useAnimation();
     const [ref, inView] = useInView();

     useEffect(() => {
          if (inView) {
            control.start("visible");
          } else {
            control.start("hidden");
          }
     }, [control, inView]);

    return(
     <motion.div
          ref={ref}
          variants={boxVariant}
          initial="hidden"
          animate={control}
        >
        <div className="aboutCompany">
            <div className="aboutCompany__row">
                 <div className="aboutCompany__item">
                     <div className="aboutCompany__itemName">{t("about.company_1")}</div>
                     <div className="aboutCompany__address">
                        <i className="aboutCompany__address-icon"></i>
                        <span className="aboutCompany__address-detail">
                          {t("about.company_address_1")}
                        </span>
                     </div>
                     <div className="aboutCompany__subAddress">
                         {t("about.company_address_1_1")}
                     </div>
                 </div>
                 <div className="aboutCompany__item">
                     <div className="aboutCompany__itemName">{t("about.company_2")}</div>
                     <div className="aboutCompany__address">
                        <i className="aboutCompany__address-icon"></i>
                        <span className="aboutCompany__address-detail">
                          {t("about.company_address_2")}
                        </span>
                     </div>
                     <div className="aboutCompany__subAddress">
                         {t("about.company_address_2_1")}
                     </div>
                 </div>
                 <div className="aboutCompany__item">
                     <div className="aboutCompany__itemName">{t("about.company_3")}</div>
                     <div className="aboutCompany__address">
                        <i className="aboutCompany__address-icon"></i>
                        <span className="aboutCompany__address-detail">
                          {t("about.company_address_3")}
                        </span>
                     </div>
                     <div className="aboutCompany__subAddress">
                         {t("about.company_address_3_1")}
                     </div>
                 </div>
                 <div className="aboutCompany__item">
                     <div className="aboutCompany__itemName">{t("about.company_4")}</div>
                     <div className="aboutCompany__address">
                        <i className="aboutCompany__address-icon"></i>
                        <span className="aboutCompany__address-detail">
                          {t("about.company_address_4")}
                        </span>
                     </div>
                     <div className="aboutCompany__subAddress">
                         {t("about.company_address_4_1")}
                     </div>
                 </div>
            </div>
        </div>
     </motion.div>
    )
}

export default Company;