import type { FC } from 'react';
import './Terabyte.less';
import TERABYTE from '../../assets/common/TERABYTE.png';

const Terabyte: FC = () => {
    return(
        <div className="terabyteContainer">
            <img src={TERABYTE} alt="" className="terabyteContainer__img" />
        </div>
    )
}

export default Terabyte;