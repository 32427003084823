import React, { useEffect } from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./CarouselProduct.less";
import CustomArrow from "../customArrow/CustomArrow";
import { useTranslation } from "react-i18next";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import banner1 from "../../../../assets/home/carousel_banner_1.png";
import banner2 from "../../../../assets/home/carousel_banner_2.png";
import banner3 from "../../../../assets/home/carousel_banner_3.png";
import banner4 from "../../../../assets/home/carousel_banner_4.png";

import bannerEn1 from "../../../../assets/home/carousel_banner_en_1.png";
import bannerEn2 from "../../../../assets/home/carousel_banner_en_2.png";
import bannerEn3 from "../../../../assets/home/carousel_banner_en_3.png";
import bannerEn4 from "../../../../assets/home/carousel_banner_en_4.png";


const boxVariant = {
  visible: { opacity: 1, translateY: 0, transition: { duration: 1 } },
  hidden: { opacity: 0 }
};

const boxVariant1 = {
  visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: .5 } },
  hidden: { opacity: 0, translateY: 100  }
};

interface CarouselProductProps {
  language: string;
}


const CarouselProduct: React.FC<CarouselProductProps> = ({ language }) => {
  const { t } = useTranslation();

   const control = useAnimation();
   const [ref, inView] = useInView();

   useEffect(() => {
      if (inView) {
        control.start("visible");
      } else {
        control.start("hidden");
      }
    }, [control, inView]);

  return (
    <div className="homePage__productCarouselWrap">
      <motion.div
          ref={ref}
          variants={boxVariant}
          initial="hidden"
          animate={control}
      >
      <div className="homePage__productTitle">
          <p className="homePage__productTitle-p">{t("home.carousel_product")}</p>
          <p className="homePage__productTitle-p">&</p>
          <p className="homePage__productTitle-p">{t("home.carousel_application")}</p>
       </div>
      </motion.div>
      <motion.div
        ref={ref}
        variants={boxVariant1}
        initial="hidden"
        animate={control}
        >

      <div className="homePage__productCarousel">
       <Carousel
            showArrows={true} //是否展示左右箭头
            showStatus={false} //是否展示右上角状态
            showIndicators={false} // 是否展示指示器
            infiniteLoop={true} //时候循环
            autoPlay={true} //是否自动播放
            stopOnHover={true} //  鼠标放上去是否停止播放
            showThumbs={false} // 是否展示轮播缩放展示图
            useKeyboardArrows={false} // 是否使用键盘左右按钮滑动
            swipeable={false} // 是否刷新
            dynamicHeight={false} //动态高度
            emulateTouch={false} // 是否拖拽滑动

            renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <CustomArrow
                direction="left"
                onClick={onClickHandler}
              />
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <CustomArrow
                direction="right"
                onClick={onClickHandler}
              />
            )
          }
       >
           <div className="homePage__productCarouselItem">
           {
            language === 'zh' ?  <img src={banner1} className="homePage__productCarouselItem-pic" alt="" />
            :  <img src={bannerEn1} className="homePage__productCarouselItem-pic" alt="" />
           }
             
           
              <div className="productCarouselItem__foot">
                  <div className="productCarouselItem__footItem">
                      <div className="productCarouselItem__footItemPic">
                          <img src={require('../../../../assets/home/carousel_server.png')} alt="" className="productCarouselItem__footItemPic-img" />
                      </div>
                      <div className="productCarouselItem__footItem-label">{t("home.carousel_server")}</div>
                  </div>
                   <div className="productCarouselItem__footItem">
                      <div className="productCarouselItem__footItemPic">
                          <img src={require('../../../../assets/home/carousel_PC.png')} alt="" className="productCarouselItem__footItemPic-img" />
                      </div>
                      <div className="productCarouselItem__footItem-label">{t("home.carousel_PC")}</div>
                  </div>
                   <div className="productCarouselItem__footItem">
                      <div className="productCarouselItem__footItemPic">
                          <img src={require('../../../../assets/home/carousel_notebooks.png')} alt="" className="productCarouselItem__footItemPic-img" />
                      </div>
                      <div className="productCarouselItem__footItem-label">{t("home.carousel_notebooks")}</div>
                  </div>
                   <div className="productCarouselItem__footItem">
                      <div className="productCarouselItem__footItemPic">
                          <img src={require('../../../../assets/home/carousel_wearable.png')} alt="" className="productCarouselItem__footItemPic-img" />
                      </div>
                      <div className="productCarouselItem__footItem-label">{t("home.carousel_wearable")}</div>
                  </div>
                </div>
            </div>

            <div className="homePage__productCarouselItem">
              {
               language === 'zh' ?  <img src={banner2} className="homePage__productCarouselItem-pic" alt="" />
               :  <img src={bannerEn2} className="homePage__productCarouselItem-pic" alt="" />
              }
              <div className="productCarouselItem__foot">
                  <div className="productCarouselItem__footItem">
                      <div className="productCarouselItem__footItemPic">
                          <img src={require('../../../../assets/home/carousel_commercial_display.png')} alt="" className="productCarouselItem__footItemPic-img" />
                      </div>
                      <div className="productCarouselItem__footItem-label">{t("home.carousel_commercial_display")}</div>
                  </div>
                   <div className="productCarouselItem__footItem">
                      <div className="productCarouselItem__footItemPic">
                          <img src={require('../../../../assets/home/carousel_PC.png')} alt="" className="productCarouselItem__footItemPic-img" />
                      </div>
                      <div className="productCarouselItem__footItem-label">{t("home.carousel_PC")}</div>
                  </div>
                   <div className="productCarouselItem__footItem">
                      <div className="productCarouselItem__footItemPic">
                          <img src={require('../../../../assets/home/carousel_notebooks.png')} alt="" className="productCarouselItem__footItemPic-img" />
                      </div>
                      <div className="productCarouselItem__footItem-label">{t("home.carousel_notebooks")}</div>
                  </div>
                   <div className="productCarouselItem__footItem">
                      <div className="productCarouselItem__footItemPic">
                          <img src={require('../../../../assets/home/carousel_wearable.png')} alt="" className="productCarouselItem__footItemPic-img" />
                      </div>
                      <div className="productCarouselItem__footItem-label">{t("home.carousel_wearable")}</div>
                  </div>
                </div>
            </div>


            <div className="homePage__productCarouselItem">
              {
                language === 'zh' ?  <img src={banner3} className="homePage__productCarouselItem-pic" alt="" />
                :  <img src={bannerEn3} className="homePage__productCarouselItem-pic" alt="" />
               }
              <div className="productCarouselItem__foot">
                  <div className="productCarouselItem__footItem">
                      <div className="productCarouselItem__footItemPic">
                          <img src={require('../../../../assets/home/carousel_server.png')} alt="" className="productCarouselItem__footItemPic-img" />
                      </div>
                      <div className="productCarouselItem__footItem-label">{t("home.carousel_server")}</div>
                  </div>
                   <div className="productCarouselItem__footItem">
                      <div className="productCarouselItem__footItemPic">
                          <img src={require('../../../../assets/home/carousel_computer_room.png')} alt="" className="productCarouselItem__footItemPic-img" />
                      </div>
                      <div className="productCarouselItem__footItem-label">{t("home.carousel_computer_room")}</div>
                  </div>
                   <div className="productCarouselItem__footItem">
                      <div className="productCarouselItem__footItemPic">
                          <img src={require('../../../../assets/home/carousel_data_center.png')} alt="" className="productCarouselItem__footItemPic-img" />
                      </div>
                      <div className="productCarouselItem__footItem-label">{t("home.carousel_data_center")}</div>
                  </div>
                   <div className="productCarouselItem__footItem">
                      <div className="productCarouselItem__footItemPic">
                          <img src={require('../../../../assets/home/carousel_edge_computing.png')} alt="" className="productCarouselItem__footItemPic-img" />
                      </div>
                      <div className="productCarouselItem__footItem-label">{t("home.carousel_edge_computing")}</div>
                  </div>
                </div>
            </div>

            <div className="homePage__productCarouselItem">
              {
                language === 'zh' ?  <img src={banner4} className="homePage__productCarouselItem-pic" alt="" />
                :  <img src={bannerEn4} className="homePage__productCarouselItem-pic" alt="" />
               }
              <div className="productCarouselItem__foot">
                  <div className="productCarouselItem__footItem">
                      <div className="productCarouselItem__footItemPic">
                          <img src={require('../../../../assets/home/carousel_intelligent_driving.png')} alt="" className="productCarouselItem__footItemPic-img two" />
                      </div>
                      <div className="productCarouselItem__footItem-label">{t("home.carousel_intelligent_driving")}</div>
                  </div>
                   <div className="productCarouselItem__footItem">
                      <div className="productCarouselItem__footItemPic">
                          <img src={require('../../../../assets/home/carousel_automotive_system.png')} alt="" className="productCarouselItem__footItemPic-img two" />
                      </div>
                      <div className="productCarouselItem__footItem-label">{t("home.carousel_automotive_system")}</div>
                  </div>
                </div>
            </div>


          </Carousel>
      </div>
       </motion.div>
    </div>
  
  );
};

export default CarouselProduct;