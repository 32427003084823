import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../redux/hook';
import { globalSlice } from '../../redux/global/slice';
import './Product.less';

import { useTranslation } from "react-i18next";


import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";


import BannerComponnet from "./component/banner/Banner";
import DDRComponent from "./component/ddr/DDR";
import LPDDRComponent from "./component/lpddr/LPDDR";
import banner1 from '../../assets/product/banner_1.png';
import banner2 from '../../assets/product/banner_2.png';
import banner3 from '../../assets/product/banner_3.png';
import banner4 from '../../assets/product/banner_4.png';
import microchipPic from '../../assets/product/microchip.png';
import microchipLpddr5 from '../../assets/product/microchip_lpddr5.png';
import memoryStick from '../../assets/product/memory_stick.png';
import UDIMM from '../../assets/product/UDIMM.png';
import { drrIcons1, drrItems1, drrIcons2, drrItems2, drrIcons3, drrItems3, drrIcons4, drrItems4, drrIcons5, drrItems5, drrIcons6, drrItems6  } from './Data';

const boxVariant = {
  visible: { opacity: 1, translateY: 0,  transition: { duration: 1, delay: .5 } },
  hidden: { opacity: 0, translateY: 100 }
};

const Product : React.FC = () => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
     useEffect(() => {
        dispatch(
          globalSlice.actions.setGlobalSlice({
           headerIn: true
          })
         )
    }, []);

    const location = useLocation();

    const targetConsumeRef = useRef<HTMLDivElement>(null);
    const targetEnterprisRef = useRef<HTMLDivElement>(null);
    const targetCarRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const hash = window.location.hash;
        if (hash && hash === '#consume') {
          // 将页面滚动到目标组件的位置
          targetConsumeRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else if(hash && hash === '#enterpris'){
            targetEnterprisRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else if(hash && hash === '#car'){
            targetCarRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [location]);

   const control = useAnimation();
   const [ref, inView] = useInView();

   useEffect(() => {
      if (inView) {
        control.start("visible");
      } else {
        control.start("hidden");
      }
    }, [control, inView]);

    return(
        <div className="productPage">
            <BannerComponnet 
                pic={banner1} 
                data1={t("product.banner_data_1")} 
                data2={t("product.banner_data_2")}  
            />
            <DDRComponent  
                title={t("product.ddr_title_1")}
                desc={t("product.ddr_desc_1")}
                rightPic={microchipPic}
                icons={drrIcons1}
                items={drrItems1}
            />
            <DDRComponent  
                title={t("product.ddr_title_2")}
                desc={t("product.ddr_desc_2")}
                rightPic={microchipPic}
                icons={drrIcons2}
                items={drrItems2}
            />
            <DDRComponent  
                title={t("product.ddr_title_3")}
                desc={t("product.ddr_desc_3")}
                rightPic={microchipPic}
                icons={drrIcons3}
                items={drrItems3}
            />
            <div ref={targetConsumeRef} >
             <BannerComponnet 
                pic={banner2}
                data1={t("product.banner_data_3")} 
                data2={t("product.banner_data_4")}  
            />
            </div>
            <DDRComponent  
                title={t("product.ddr_title_4")}
                desc={t("product.ddr_desc_4")}
                rightPic={memoryStick}
                icons={drrIcons4}
                items={drrItems4}
            />
            <div ref={targetEnterprisRef}>
              <BannerComponnet 
                pic={banner3}
                data1={t("product.banner_data_5")} 
                data2={t("product.banner_data_6")}  
            />
            </div>
            <DDRComponent  
                title={t("product.ddr_title_5")}
                desc={t("product.ddr_desc_5")}
                rightPic={UDIMM}
                icons={drrIcons5}
                items={drrItems5}
            />
            <div ref={targetCarRef}>
              <BannerComponnet 
                pic={banner4}
                data1={t("product.banner_data_7")} 
                data2={t("product.banner_data_8")}  
                />
            </div>
             <LPDDRComponent  
                title={t("product.ddr_title_6")}
                desc={t("product.ddr_desc_6")}
                rightPic={microchipLpddr5}
                icons={drrIcons6}
                items={drrItems6}
            />
            <motion.div
                  ref={ref}
                  variants={boxVariant}
                  initial="hidden"
                  animate={control}
                >
            <div className="productPage__foot" >
               {t("product.foot_text")}
            </div>
            </motion.div>

        </div>
    )
}

export default Product;