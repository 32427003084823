import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { BrowserRouter} from "react-router-dom";
import RenderRouter from "./routes";
import './i18n/configs';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <RenderRouter />
    </BrowserRouter>
  </Provider>
);

