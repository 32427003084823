import React, { useEffect } from "react";
import './Banner.less';

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useSelector } from '../../../../redux/hook';

interface BannerProps {
    pic: string;
    data1: string;
    data2: string;
}

const boxVariant = {
  visible: { opacity: 1, translateY: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, translateY: 100 }
};

const Banner : React.FC<BannerProps> = (props) => {
    const { pic, data1, data2} = props;
    const { language } = useSelector(state => state.global); 

    const control = useAnimation();
    const [ref, inView] = useInView();

   useEffect(() => {
      if (inView) {
        control.start("visible");
      } else {
        control.start("hidden");
      }
    }, [control, inView]);

    return(
    <motion.div
          ref={ref}
          variants={boxVariant}
          initial="hidden"
          animate={control}
      >
        <div className="productBanner">
            <img src={pic} alt="" className="productBanner__pic" />
             <div className={language === 'en' ? "productBanner__title en" : "productBanner__title"}>
                <div className="productBanner__title-data">{data1}</div>
                <div className="productBanner__title-data">{data2}</div>
            </div>

        </div>
     </motion.div>
    )
}

export default Banner;