import React from "react";
import './Map.less';
import bigBanner from '../../../../assets/quality/big_banner.png';
import bigBannerEn from '../../../../assets/quality/big_banner_en.png';

interface MapProps {
	language: string;
}

const Map: React.FC<MapProps> = ({ language }) => {
    return(
        <div className="qualityMapContainer">
           { language === 'zh' ? <img src={bigBanner} alt="" className="qualityMap_pic" /> : <img src={bigBannerEn} alt="" className="qualityMap_pic" />}
        </div>
    )
}

export default Map;