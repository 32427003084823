import React, { useEffect } from "react";
import "./Intro.less";
import { useTranslation } from "react-i18next";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import TERABYTE from '../../../../assets/home/TERABYTE.png';
import goddesses from '../../../../assets/home/goddesses.png';


const boxVariant = {
  visible: { opacity: 1, translateY: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, translateY: 100}
};

const boxVariant1 = {
  visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: .5 } },
  hidden: { opacity: 0, translateY: 150}
};

const boxVariant2 = {
  visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: 1 } },
  hidden: { opacity: 0, translateY: 100}
};

interface IntroProps {
  language: string;
}

const Intro: React.FC<IntroProps> = ({ language }) => {
  const { t } = useTranslation();
   
  const control = useAnimation();
   const [ref, inView] = useInView();

   useEffect(() => {
      if (inView) {
        control.start("visible");
      } else {
        control.start("hidden");
      }
    }, [control, inView]);

  return (
    <div className="homePage__introWrap">
      <motion.div
          ref={ref}
          variants={boxVariant}
          initial="hidden"
          animate={control}
        >
          <div className="homePage__introSlogan"> 
                <img className="homePage__introSlogan-img"  src={TERABYTE} alt=""/>
            </div>
        </motion.div>
            <div className="homePage__introRow">
            <motion.div
                ref={ref}
                variants={boxVariant1}
                initial="hidden"
                animate={control}
              >
                <div className="homePage__introLeft">
                  <img className="homePage__introLeft-image" src={goddesses} alt=""/>
                </div>
               </motion.div>
                <div className="homePage__introRight">
                 <motion.div
                    ref={ref}
                    variants={boxVariant1}
                    initial="hidden"
                    animate={control}
                  >
                   <div className="homePage__intro-title">
                    {t("home.intro_title")}
                   </div>
                  </motion.div>
                   <motion.div
                    ref={ref}
                    variants={boxVariant2}
                    initial="hidden"
                    animate={control}
                  >
                   <div className={language === 'en' ? "homePage__intro-info en" : "homePage__intro-info"}>
                    <p className="homePage__intro-info-p">
                      {t("home.intro_info_1")}
                      <br />
                      <br />
                      {language === 'zh' && <br />}
                      {t("home.intro_info_2")}
                    </p>
                   </div>
                  </motion.div>
                </div>
            </div>
    </div>
  );
};

export default Intro;