import React from "react";
import './Footer.less';
import { Link } from "react-router-dom";
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import { useSelector, useAppDispatch } from '../../redux/hook';
import { globalSlice } from '../../redux/global/slice';
import Logo from '../../assets/common/logo.png';
import iconLng from '../../assets/common/icon_lng.png';

export const Footer: React.FC = () => {
    const { t } = useTranslation();
    const { language } = useSelector(state => state.global);
    const dispatch = useAppDispatch();
    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
        dispatch(
          globalSlice.actions.changeLanguage({
            language: lng
          }),
      );
    }
    return (
        <div className="footerContainer">
           <div className="footerContainer__row">
                <div className="footerContainer__logo">
                    <Link to="/" >
                        <img src={Logo} alt="" className="footerContainer__logo-image" />
                    </Link>
                </div>
                <div className="footerContainer__menu">
             
                    <div className="footerContainer__menuCol">
                        <Link to="/product" className="footerContainer__menu-title" >
                            {t("header.menu.title_product")}
                        </Link>
                        <div className="footerContainer__items">
                            <Link to="/product" className="footerContainer__menu-itemLink" >
                             {t("header.menu.title_product_1")}
                            </Link>
                            <Link to="/product#consume" className="footerContainer__menu-itemLink" >
                             {t("header.menu.title_product_2")}
                            </Link>
                            <Link to="/product#enterpris" className="footerContainer__menu-itemLink" >
                             {t("header.menu.title_product_3")}
                            </Link>
                            <Link to="/product#car" className="footerContainer__menu-itemLink" >
                             {t("header.menu.title_product_4")}
                            </Link>
                        </div>
                    </div>
                    <div className="footerContainer__menuCol">
                        <Link to="/quality" className="footerContainer__menu-title" >
                            {t("header.menu.title_hons")}
                        </Link>
                        <div className="footerContainer__items">
                            <Link to="/quality" className="footerContainer__menu-itemLink" >
                             {t("header.menu.title_hons_1")}
                            </Link>
                            <Link to="/quality#honors" className="footerContainer__menu-itemLink" >
                             {t("header.menu.title_hons_2")}
                            </Link>
                        </div>
                    </div>
                    <div className="footerContainer__menuCol">
                        <Link to="/about" className="footerContainer__menu-title" >
                            {t("header.menu.title_about")}
                        </Link>
                        <div className="footerContainer__items">
                            <Link to="/about#introduce" className="footerContainer__menu-itemLink" >
                             {t("header.menu.title_about_1")}
                            </Link>
                            <Link to="/about#history" className="footerContainer__menu-itemLink" >
                             {t("header.menu.title_about_2")}
                            </Link>
                            <Link to="/about#culture" className="footerContainer__menu-itemLink" >
                             {t("header.menu.title_about_3")}
                            </Link>
                            <Link to="/about#contact" className="footerContainer__menu-itemLink" >
                             {t("header.menu.title_about_4")}
                            </Link>
                        </div>
                      </div>  
                </div>
                <div className="footerContainer__language">
                    <img src={iconLng} alt="" className="footerContainer__language-ico" />
                    <a className={language== 'zh' ? "footerContainer__language-link active": "footerContainer__language-link "}  onClick={() => changeLanguage('zh')}>中文</a>
                    &nbsp;&nbsp;/&nbsp;&nbsp;
                    <a className={language== 'en' ? "footerContainer__language-link active": "footerContainer__language-link "} onClick={() => changeLanguage('en')} >EN</a>
                </div>
            </div>
            <div className="footerContainer__copyright">
              {t("footer.detail")}
            </div>
        </div>
     )    
}