import React, { useEffect } from "react";
import "./Cooper.less";
import { useTranslation } from "react-i18next";


import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const cooperData = [{
  src: require('../../../../assets/home/cooper_amd.png'),
},
{
  src: require('../../../../assets/home/cooper_intel.png'),
},
{
  src: require('../../../../assets/home/cooper_mediatek.png'),
},
{
  src: require('../../../../assets/home/cooper_mlogic.png'),
},
{
  src: require('../../../../assets/home/cooper_guokewei.png'),
},
{
  src: require('../../../../assets/home/cooper_realtek.png'),
},
{
  src: require('../../../../assets/home/cooper_qualcomm.png'),
},
{
  src: require('../../../../assets/home/cooper_hisilicon.png'),
},
{
  src: require('../../../../assets/home/cooper_fatc.png'),
},
{
  src: require('../../../../assets/home/cooper_samsung.png'),
},
{
  src: require('../../../../assets/home/cooper_skhynix.png'),
},
{
  src: require('../../../../assets/home/cooper_nanya.png'),
},
{
  src: require('../../../../assets/home/cooper_yageo.png'),
},
{
  src: require('../../../../assets/home/cooper_indium.png'),
},
{
  src: require('../../../../assets/home/cooper_drain_power.png'),
},
{
  src: require('../../../../assets/home/cooper_corsair.png'),
},
{
  src: require('../../../../assets/home/cooper_apacer.png'),
},
{
  src: require('../../../../assets/home/cooper_thunderobot.png'),
},
{
  src: require('../../../../assets/home/cooper_star_net.png'),
},
{
  src: require('../../../../assets/home/cooper_skyworth.png'),
},
{
  src: require('../../../../assets/home/cooper_huasuo.png'),
},
{
  src: require('../../../../assets/home/cooper_acer.png'),
},
{
  src: require('../../../../assets/home/cooper_zte.png'),
},
{
  src: require('../../../../assets/home/cooper_hisense.png'),
}]


const boxVariant = {
  visible: { opacity: 1, translateY: 0,  transition: { duration: 1 } },
  hidden: { opacity: 0, translateY: 100 }
};

const boxVariant1 = {
  visible: { opacity: 1, translateY: 0,  transition: { duration: 1, delay: .5 } },
  hidden: { opacity: 0, translateY: 100 }
};

const boxVariant2 = {
  visible: { opacity: 1, translateY: 0,  transition: { duration: 1, delay: 1 } },
  hidden: { opacity: 0, translateY: 100 }
};

const boxVariant3 = {
  visible: { opacity: 1, translateY: 0,  transition: { duration: 1, delay: 1.5 } },
  hidden: { opacity: 0, translateY: 100 }
};

const boxVariant4 = {
  visible: { opacity: 1, translateY: 0,  transition: { duration: 1, delay: 2 } },
  hidden: { opacity: 0, translateY: 100 }
};

const Cooper: React.FC = () => {
  const { t } = useTranslation();

   const control = useAnimation();
   const [ref, inView] = useInView();

   useEffect(() => {
      if (inView) {
        control.start("visible");
      } else {
        control.start("hidden");
      }
    }, [control, inView]);

  return (
    <div className="homePage__cooperWrap">
      <motion.div
          ref={ref}
          variants={boxVariant}
          initial="hidden"
          animate={control}
    >
      <div className="cooper__title">
      	 {t("home.cooper_title")}
      </div>
     </motion.div>
      <motion.div
          ref={ref}
          variants={boxVariant1}
          initial="hidden"
          animate={control}
      >
      <div className="cooper__list">
      	{
          cooperData.map((item,index)  => {
            return(
            index < 6 && <div className="cooper__item" key={'cooper__item_' + index}>
                <img src={item.src} alt="" className="cooper__itemPic" />
            </div>        
            )
          })
        }
      </div>
     </motion.div>

      <motion.div
          ref={ref}
          variants={boxVariant2}
          initial="hidden"
          animate={control}
      >
      <div className="cooper__list">
        {
          cooperData.map((item,index)  => {
            return(
            (index > 5 && index < 12) && <div className="cooper__item" key={'cooper__item_' + index}>
                <img src={item.src} alt="" className="cooper__itemPic" />
            </div>        
            )
          })
        }
      </div>
     </motion.div>

      <motion.div
          ref={ref}
          variants={boxVariant3}
          initial="hidden"
          animate={control}
      >
      <div className="cooper__list">
        {
          cooperData.map((item,index)  => {
            return(
            (index > 11 && index < 18) && <div className="cooper__item" key={'cooper__item_' + index}>
                <img src={item.src} alt="" className="cooper__itemPic" />
            </div>        
            )
          })
        }
      </div>
     </motion.div>

      <motion.div
          ref={ref}
          variants={boxVariant4}
          initial="hidden"
          animate={control}
      >
      <div className="cooper__list">
        {
          cooperData.map((item,index)  => {
            return(
            (index > 17 && index < 24) && <div className="cooper__item" key={'cooper__item_' + index}>
                <img src={item.src} alt="" className="cooper__itemPic" />
            </div>        
            )
          })
        }
      </div>
     </motion.div>

    </div>
   
  );
};

export default Cooper;