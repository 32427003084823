export const drrIcons1 = [{
    icon: require('../../assets/product/icon_beta.png'),
    label: '1β制程',
    en: '1β Process Node'
},{
    icon: require('../../assets/product/icon_performance.png'),
    label: '高性能',
    en: 'High Performance'
},{
    icon: require('../../assets/product/icon_quality.png'),
    label: '品质可靠',
    en: 'Reliable Quality'
},{
    icon: require('../../assets/product/icon_fill.png'),
    label: '供货稳定',
    en: 'Stable Supply'
}]

export const drrItems1 = [{
    pic: require('../../assets/product/server.png'),
    label: '服务器',
    en: 'Servers'
},{
    pic: require('../../assets/product/pc.png'),
    label: '台式电脑',
    en: 'Desktop Computer'
},{
    pic: require('../../assets/product/notebook.png'),
    label: '笔记本电脑',
    en: 'Laptop'
},{
    pic: require('../../assets/product/Cloud_Computing.png'),
    label: '云计算',
    en: 'Cloud Computing'
}]

export const drrIcons2 = [{
    icon: require('../../assets/product/icon_different.png'),
    label: '差异化',
    en: 'Differentiated'
},{
    icon: require('../../assets/product/icon_quality_price.png'),
    label: '高性价比',
    en: 'Cost-Effective'
},{
    icon: require('../../assets/product/icon_accreditation.png'),
    label: '多平台认证',
    en: 'Broadly Recognized'
},{
    icon: require('../../assets/product/icon_fill.png'),
    label: '供货稳定',
    en: 'Stable Supply'
}]


export const drrItems2 = [{
    pic: require('../../assets/product/ott.png'),
    label: 'OTT',
    en: 'OTT'
},{
    pic: require('../../assets/product/smart_TV.png'),
    label: '智能电视',
    en: 'Smart TV'
},{
    pic: require('../../assets/product/smart_monitoring.png'),
    label: '智能监控',
    en: 'Intelligent Monitoring'
},{
    pic: require('../../assets/product/robots.png'),
    label: '机器人',
    en: 'Robot'
}]


export const drrIcons3 = [{
    icon: require('../../assets/product/icon_high_quality.png'),
    label: '高性价比',
    en: 'Cost-Effective'
},{
    icon: require('../../assets/product/icon_quality.png'),
    label: '品质可靠',
    en: 'Reliable Quality'
},{
    icon: require('../../assets/product/icon_fill.png'),
    label: '供货稳定',
    en: 'Stable Supply'

},{
    icon: require('../../assets/product/icon_accreditation.png'),
    label: '多平台认证',
    en: 'Broadly Recognized'
}]

export const drrItems3 = [{
    pic: require('../../assets/product/smart_speaker.png'),
    label: '智能音箱',
    en: 'Smart Speaker'
},{
    pic: require('../../assets/product/router.png'),
    label: '路由器',
    en: 'Router'
},{
    pic: require('../../assets/product/optical_modem.png'),
    label: '光猫',
    en: 'Optical Modem'
},{
    pic: require('../../assets/product/Set_top_Box.png'),
    label: '机顶盒',
    en: 'Set-top Box'
}]


export const drrIcons4 = [{
    icon: require('../../assets/product/icon_DDR5.png'),
    label: 'DDR5',
    en: 'DDR5'
},{
    icon: require('../../assets/product/icon_performance.png'),
    label: '高性能',
    en: 'High Performance'
},{
    icon: require('../../assets/product/icon_quality.png'),
    label: '品质可靠',
    en: 'Reliable Quality'
},{
    icon: require('../../assets/product/icon_fill.png'),
    label: '供货稳定',
    en: 'Stable Supply'
}]

export const drrItems4 = [{
    pic: require('../../assets/product/Mini_PC.png'),
    label: '迷你PC', 
    en: 'Mini PC'
},{
    pic: require('../../assets/product/pc.png'),
    label: '台式电脑',
    en: 'Desktop Computer'
},{
    pic: require('../../assets/product/notebook.png'),
    label: '笔记本电脑',
    en: 'Laptop'
},{
    pic: require('../../assets/product/NAS.png'),
    label: 'NAS私有云', 
    en: 'NAS'
}]


export const drrIcons5 = [{
    icon: require('../../assets/product/icon_high_standard.png'),
    label: '高标准',
    en: 'High Standards'
},{
    icon: require('../../assets/product/icon_quality.png'),
    label: '品质稳定',
    en: 'Reliable Quality'
},{
    icon: require('../../assets/product/icon_accreditation.png'),
    label: '行业认证',
    en: 'Broadly Recognized'
},
{
    icon: require('../../assets/product/icon_performance.png'),
    label: '高性能',
    en: 'High Performance'
}]


export const drrItems5 = [{
    pic: require('../../assets/product/data_center.png'),
    label: '数据中心',
    en: 'Data Centers'
},{
    pic: require('../../assets/product/server.png'),
    label: '服务器',
    en: 'Servers'
},{
    pic: require('../../assets/product/computer_room.png'),
    label: '企业机房',
    en: 'Computer Rooms'
},{
    pic: require('../../assets/product/edge_computing.png'),
    label: '边缘计算',
    en: 'Edge Computing'
}]

export const drrIcons6 = [{
    icon: require('../../assets/product/icon_IATF.png'),
    label: '符合IATF16949',
    en: 'IATF16949'
},{
    icon: require('../../assets/product/icon_quality.png'),
    label: '品质稳定',
    en: 'Reliable Quality'
},
{
    icon: require('../../assets/product/icon_performance.png'),
    label: '高性能',
    en: 'High Performance'
},
{
    icon: require('../../assets/product/icon_pressure.png'),
    label: '高抗压',
    en: 'High Compressive Strength'
}]


export const drrItems6 = [{
    pic: require('../../assets/product/intelligent_driving.png'),
    label: '智能驾驶',
    en: 'Smart Driving'
},{
    pic: require('../../assets/product/automotive_system.png'),
    label: '车载系统',
    en: 'Vehicle Systems'
}]


