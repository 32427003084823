import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

interface GlobalState {
    language: "en" | "zh";
    languageList: { name: string, code: string } [];
    headerIn: boolean;
    menuIdx: number;
}

const initialState: GlobalState = {
    language: "zh",
    languageList: [
        { name: "中文", code: "zh"},
        { name: "English", code: "en"}
    ],
    headerIn: false,
    menuIdx: 0
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    changeLanguage:(state, action) => {
       const { language } = action.payload;
       Object.assign(state, action.payload);
    },
    setGlobalSlice: (state, action) => {
       Object.assign(state, action.payload);
    }
  },
});
