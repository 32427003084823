import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { actionLog } from './middlewares/actionLog';
import { globalSlice } from './global/slice';

const rootReducer = combineReducers ({
    global: globalSlice.reducer,
})


const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(actionLog),
    devTools: true,
})


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;

export { store };