import React, { useEffect } from "react";
import './DDR.less';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useSelector } from '../../../../redux/hook';

interface DDRProps{
    title: string;
    desc: string;
    rightPic?: string;
    icons: Array<{icon: string; label: string; en: string; }>;
    items: Array<{pic: string; label: string; en: string; }>;
}


const boxVariant = {
  visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: .5 } },
  hidden: { opacity: 0, translateY: 100 }
};

const DDR : React.FC<DDRProps> = (props) => {
    const { title,  desc, rightPic, icons, items } = props;

    const { language } = useSelector(state => state.global); 

    const control = useAnimation();
    const [ref, inView] = useInView();
    
     useEffect(() => {
      if (inView) {
        control.start("visible");
      } else {
        control.start("hidden");
      }
    }, [control, inView]);

    return(
     <motion.div
          ref={ref}
          variants={boxVariant}
          initial="hidden"
          animate={control}
      >
        <div className="productDDR">
            <div className="content-row">
                <div className={language === 'en' ? "productDDR__top en" : "productDDR__top"}>
                    <div className="productDDR__topTitle">{title}</div>
                    <div className="productDDR__topDesc">{desc}</div>
                </div>
                <div className="productDDR__middle">
                     <div className="productDDR__middleLeft">
                        <div className="productDDR__iconsRow">
                            {
                               icons.map(item => {
                                 return(
                                <div className="productDDR__icon">
                                    <img src={item.icon} alt="" className="productDDR__icon-pic" />  
                                    <div className={language === 'en' ? "productDDR__icon-label en" : "productDDR__icon-label"} >
                                      {language === 'en' ? item.en  : item.label}
                                    </div>  
                                  </div>
                                 )
                               }) 
                            }
                        </div>
                     </div>
                     <div className="productDDR__middleRight">
                        <img src={rightPic} alt="" className="productDDR__middleRight-pic" />
                     </div>
                </div>
                <div className="productDDR__foot">
                    {
                        items.map(item => { 
                            return(
                                <div className="productDDR__footItem">
                                    <div className="productDDR__footItemPic">
                                        <img src={item.pic} alt="" className="productDDR__footItemPic-img" />
                                    </div>
                                    <div className="productDDR__footItem-label">{language === 'en' ? item.en  : item.label}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
        </motion.div>
    )
}

export default DDR;